* {
  box-sizing: border-box;
}

@keyframes ticker {
  0% {
    left: -100%;
  }
  50% {
    left: -50%;
  }
  100% {
    left: 0;
  }
}
