@font-face {
  font-family: "Hubot-Sans-Semi-bold-600";
  src: url("./common/assets//fonts/Hubot-Sans-SemiBold.otf");
}

@font-face {
  font-family: "Hubot-Sans-Medium-500";
  src: url("./common/assets//fonts/Hubot-Sans-Medium.otf");
}

@font-face {
  font-family: "Hubot-Sans-Normal-400";
  src: url("./common/assets//fonts/Hubot-Sans-Regular.otf");
}

*,
*::before,
*::after {
  box-sizing: border-box !important;
  scrollbar-width: thin;
  scrollbar-color: #c2c2bf transparent;
}

body {
  margin: 0;
  font-family:
    "Hubot-Sans-Medium-500",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Hubot-Sans-Medium-500", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.pac-container {
  margin-top: 20px;
  box-shadow: unset;
  border: 0;
  border-radius: 8px;
  background-color: #fdfdfd;
  overflow: hidden;
}

.pac-container::after {
  display: none;
}

.pac-item {
  background-color: #fdfdfd;
  padding: 4px;
  cursor: pointer;
  border: 0;
  font-family: Hubot-Sans-Normal-400;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #696960;
}

.pac-item .pac-item-query {
  font-family: Hubot-Sans-Normal-400;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  color: #696960;
}

.pac-item:hover > .pac-item-query {
  color: #13130c;
}

.pac-item:hover {
  color: #13130c;
  background-color: #fdfdfd;
}

.pac-icon.pac-icon-marker {
  display: none;
}

*::-webkit-scrollbar {
  width: 12px;

  -webkit-appearance: none;
}

*::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #c2c2bf;
}
